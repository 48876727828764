.contact-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
#contact-us {
  padding-top: 80px;
}
.contact-title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
}

.contact-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.contact-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: calc(33.333% - 40px);
  margin: 10px;
  max-width: 300px;
}

.contact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(3, 32, 86, 0.438);
}

.contact-card-image {
  margin-top: 20px;
  border-radius: 100px;

  width: 100px;
  height: auto;
}

.contact-card-body {
  padding: 20px;
}

.contact-card-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.contact-info a {
  display: block;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
  margin-bottom: 10px;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-info div {
  display: block;
  /*display:flex;   for name:cont inline
  justify-content: space-between; */
  align-items: center;
  margin-bottom: 10px;
}

.contact-info div i {
  margin-left: 5px;
}

.contact-card-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-card-button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .contact-card {
    width: calc(100% - 20px);
    margin: 10px 0;
  }

  .contact-section {
    padding: 10px;
  }

  .contact-card-body {
    padding: 10px;
  }

  .contact-card-title {
    font-size: 20px;
  }

  .contact-card-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
