/* 



body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .section {
    text-align: center;
    background-color: #0080ff;
    min-height: 100vh; 
  }
   */
.section h1 {
  margin-top: 80px;
}
