.poetsen-one-regular {
  text-align: center;
}

.card-logo {
  width: 200px !important;
  height: 200px !important;
}
.card-container {
  justify-content: center;
  text-align: center;
  margin: auto;
  box-shadow: 0 0 8px rgba(156, 189, 255, 0.3), 0 0 8px rgba(192, 213, 255, 0.3),
    0 0 8px rgba(189, 211, 255, 0.3), 0 0 8px rgba(185, 209, 255, 0.3);
}

/*.container {
  background-color: rgb(250, 250, 250);
  box-shadow: 10px 10px rgba(97, 176, 255, 0.5);
}*/

.footer-container {
  justify-content: space-between;
}

.footer-text {
  text-align: center;
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
