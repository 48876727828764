.section {
  padding: 10px 10px;
  text-align: center;
}

.section h2 {
  font-size: 28px !important;
  margin-bottom: 10px;
  font-size: 2.5rem;
  text-align: center;
}

.section img {
  width: 100%;
  height: auto;
}

.inter-400 p {
  text-align: justify;
  padding: 5px;
  margin: 2px 3px;
}
.poetsen-one-regular {
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inter-400,
.inter-400 h5,
.inter-400 p {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 425;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  text-align: justify;
}

/* AssociationPage.css */

.container {
  display: flex;
  justify-content: center;
}

.card-container {
  width: 95%;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding: 20px;
  margin-top: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.card-content h2 {
  margin-bottom: 20px;
}

.card-content p {
  margin-bottom: 10px;
}

.inter-400 h5 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  text-align: left;
  margin-top: auto;
}

.inter-400 p {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  text-align: justify;
  padding: 10px;
}
