.leadership-header {
  background-color: #c95656;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

.leadership-header h1 {
  margin: 0;
  font-size: 36px;
}

.leadership-header p {
  margin: 10px 0;
  font-size: 18px;
}

.leadership-section {
  margin: 20px auto;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.leadership-section h2 {
  color: #333;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 24px;
}

.leadership-table-container {
  overflow-x: auto;
}

.leadership-table {
  width: 100%;
  border-collapse: collapse;
}

.leadership-table th,
.leadership-table td {
  padding: 15px;
  text-align: left;
}

.leadership-table th {
  background-color: #c1d1e2;
  font-weight: bold;
  text-transform: uppercase;
  color: #000000;
}

.leadership-table td {
  color: #000000;
  border-bottom: 1px solid #f2f2f2;
}

.leadership-role {
  font-weight: bold;
  color: #333;
}

.leadership-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 425;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  text-align: justify;
  padding: 6px;
}
