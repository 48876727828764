.section {
  padding: 20px;
}

.poetsen-one-regular {
  font-family: "Poetsen One", sans-serif;
  text-align: center;
}

.events-section {
  margin: 20px 0;
}

.events-heading {
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
}

.events-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.event-card {
  background: #fff;
  border: 1px solid #dcd8d8;
  border-radius: 20px;
  margin: 20px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 300px;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(255, 0, 0, 0.2);
}

.event-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.event-title {
  font-size: 1.2em;
  margin: 10px 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, 1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 80px; /* Adjust for navbar height */
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  max-height: 90vh;
  overflow-y: auto;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #fff;
  background-color: rgb(234, 92, 92);
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 1001; /* Ensure it stays on top */
}

.image-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.gallery-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.nav-button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  font-size: 2em;
  color: #fff;
  cursor: pointer;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001; /* Ensure they stay on top */
}

.nav-button.left {
  left: 10px;
}

.nav-button.right {
  right: 10px;
}

.nav-button:hover {
  background: rgba(0, 0, 0, 0.7);
}
