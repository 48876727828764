/* General styles for all screen sizes */
.navbar {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: flex;
  align-items: center;
  font-size: 20px !important;
}

.navbar-toggler {
  font-size: 24px;
}

.nav-link {
  color: #fff !important;
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px !important;
  margin: 0 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-nav {
    margin-top: 20px;
  }
  .nav-link {
    font-size: 18px !important;
  }
}

@media (max-width: 576px) {
  .navbar-brand {
    font-size: 18px !important;
  }
  .nav-link {
    font-size: 16px !important;
  }
}

@media (max-width: 375px) {
  .navbar-brand {
    font-size: 16px !important;
  }
  .nav-link {
    font-size: 14px !important;
  }
  .navbar {
    padding: 0 10px; /* Add padding to ensure content fits */
  }
}

@media (max-width: 320px) {
  .navbar-brand {
    font-size: 14px !important;
  }
  .nav-link {
    font-size: 12px !important;
  }
  .navbar {
    padding: 0 5px; /* Add padding to ensure content fits */
  }
}

/* Styles for smartwatches or very small screens */
@media (max-width: 200px) {
  .navbar-brand {
    display: none;
  }
  .nav-link {
    display: none;
  }
  .navbar-toggler {
    display: block;
  }
}

.navbar-collapse.show {
  background-color: #343a40;
}

.navbar-nav .nav-item .nav-link.active {
  color: #fff;
}

.title {
  padding-left: 20px !important;
}

.rounded-circle {
  margin-left: 6px;
}

.poetsen-one-regular {
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
