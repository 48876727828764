/* General styles for all paragraphs */
p {
  text-align: justify;
  padding: 5px;
  margin: 2px 3px;
  margin-bottom: 15px; /* Add margin bottom for spacing between paragraphs */
  line-height: 2; /* Adjust line spacing within paragraphs */
}

/* Increase spacing between sections */
.section {
  padding: 20px 10px; /* Adjust padding for better spacing */
}

/* Additional spacing for headings */
.section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px; /* Increase margin bottom for spacing between headings and paragraphs */
  text-align: center;
}

.section h2 {
  font-size: 2rem; /* Adjust size if necessary */
  margin-bottom: 20px; /* Increase margin bottom for spacing between headings and paragraphs */
  text-align: center;
}

/* Adjust spacing for container */
.container {
  flex-direction: column; /* Ensure content stacks vertically */
  align-items: center;
  padding: 20px; /* Adjust padding for better spacing */
  margin: 0 auto; /* Center the container */
  border-radius: 10px;
  background-color: #f8f9fa; /* Optional: add a background color for better visibility */
}

/* Add spacing for card content */
.card-container {
  width: 90%; /* Ensure card container takes full width of parent container */
  max-width: 800px; /* Set max-width to ensure container doesn't stretch too much */
}

.card-content {
  padding: 10px; /* Adjust padding for card content */
  width: 100%; /* Ensure card content takes full width of card container */
}

/* Increase spacing for h5 */
h5 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  text-align: left;
  margin-top: auto;
  margin-bottom: 10px; /* Add margin bottom for spacing */
}
